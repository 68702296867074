import { CaseStudyCategory } from "../CaseStudyCategory.js";
import { Locale, type LocalizedString } from "../Locale.js";

export const caseStudyCategoryNameMap: Record<CaseStudyCategory, LocalizedString> = {
	[CaseStudyCategory.Agv]: {
		[Locale.cs]: "Automatizace",
	},
	[CaseStudyCategory.DurableContainers]: {
		[Locale.cs]: "Boxy a přepravky",
	},
	[CaseStudyCategory.ShelfSystem]: {
		[Locale.cs]: "Regálové systémy",
	},
	[CaseStudyCategory.SteelPlatforms]: {
		[Locale.cs]: "Ocelové plošiny",
	},
	[CaseStudyCategory.StorageTowers]: {
		[Locale.cs]: "Skladovací věže",
	},
};
